<template>
  <div class="container">
    <!-- 3213 -->
    <div class="goods-main">
      <!-- 左侧选图区 -->
      <!-- <div class="goods-main-left">

      </div> -->
      <!-- 中间放大镜区域 -->
      <!-- <div class="goods-main-mid"> -->
      <GoodsImgs :images="dataObj.pictureList" />
      <!-- </div> -->
      <!-- 商品SKU选择区 -->
      <div class="goods-main-right">
        <div class="goods-title ellipsis-3">
          {{dataObj.title}}
        </div>
        <p class="goods-price-bar">
          <span class="price"
                v-if="isVal == 0">&yen;{{dataObj.curSpePrice}}</span>
          <span class="price"
                v-if="isVal == 1">{{dataObj.defaultContributionValue}} 贡献值</span>
          <span class="d-price"
                v-if="dataObj.marketPrice">&yen;{{  dataObj.marketPrice}}</span>
        </p>
        <p class="numStore">库存：{{dataObj.numStore}}</p>
        <div class="goods-sku">
          <div class="sku">
            <div class="sku-item"
                 v-for="(item, index) in dataObj.attrSku"
                 :key="index">
              <div class="specs-name">{{ item.attrKey }}</div>
              <div v-for="(sitem, sindex) in item.goodsSpecsAttrs"
                   :key="sindex"
                   class="suk-color ellipsis"
                   :class="{'suk-color-active' : sitem.active} "
                   @click="checkspecis(index, sindex)"
                   :title=" sitem.attrValue">
                {{ sitem.attrValue }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 小程序码扫码区 -->
      <div class="mini-programer-code">
        <img class="code-pic"
             :src="shareUrl"
             alt="">
        微信扫码立即购买
      </div>
    </div>
    <div class="goods-detail">
      <!-- 切换栏 -->
      <div class="goods-detail-tab">
        <div class="goods-detail-tab">
          <div class="goods-tab-item"
               :class="{'active' : tabIndex === 1}"
               @click="tabIndex = 1">详情</div>
          <div class="goods-tab-item"
               v-show="goodsParamList && goodsParamList.length"
               :class="{'active' : tabIndex === 2}"
               @click="tabIndex = 2">参数</div>
        </div>
      </div>
      <!-- 详情区域 -->
      <div class="goods-detail-content"
           v-show=" tabIndex === 1"
           v-html="dataObj.paramDetail">
      </div>
      <!-- 参数栏 -->
      <div class="goods-detail-params"
           v-show=" tabIndex === 2">
        <div class="table">
          <div class="table-line"
               v-for="(item, index) in goodsParamList"
               :key="index">
            <p class="table-name">{{item.name}}</p>
            &nbsp;
            <p class="table-value">{{item.val}}</p>
          </div>
        </div>
      </div>
      <div class="goods-detail-content"
           v-show="!dataObj.paramDetail && dataObj.goodsParamList &&dataObj.goodsParamList.length == 0">
        <qdzEmpty state="notData" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import GoodsImgs from './components/goodsImgs.vue'
import { ApiGetGoodsDetail, ApiGetAppletsShareCode, ApiAddParamSign } from '@/api/goods'
export default {
  components: {
    GoodsImgs
  },

  setup () {
    const route = useRoute()
    const state = reactive({
      dataObj: {},
      tabIndex: 1,
      goodsParamList: [],
      shareUrl: '',
      isVal: 0  // 判断是否从贡献值页面进入的
    })
    const handleGetGoodsDetail = async () => {
      let res = await ApiGetGoodsDetail({
        goodsId: route.query.goodsId
      })
      if (res.code === "10000") {
        state.dataObj = res.data
        // console.log(res.data)
        state.goodsParamList = res.data.goodsParamList && JSON.parse(res.data.goodsParamList)
      }
      if (res.code == 10000) {
        let data = res.data;
        // 修改商品标签格式
        let goodsTagName = data.goodsTagName;
        if (goodsTagName != "" && goodsTagName != null) {
          if (goodsTagName.indexOf("/") != -1) {
            data.goodsTagName = goodsTagName.split("/");
          } else {
            data.goodsTagName += "/";
            data.goodsTagName = goodsTagName.split("/");
          }
        }

        // 为规格字段添加是否选中字段 active
        data.attrSku.map((item, index) => {
          if (item.goodsSpecsAttrs.length == 1) {
            item.goodsSpecsAttrs[0].active = true;
          } else {
            item.goodsSpecsAttrs.map((itemTwo) => {
              itemTwo.active = false;
            });
          }
        });

        // 商品参数
        if (data.goodsParamList) {
          let goodsParamList = data.goodsParamList;
          if (
            goodsParamList != "" &&
            goodsParamList != null &&
            goodsParamList.length != 0
          ) {
            data.goodsParamList = !!goodsParamList
              ? JSON.parse(goodsParamList)
              : [];

            // 新增商品参数外显字段
            data.goodsParamShow = "";
            data.goodsParamList.map((item) => {
              data.goodsParamShow += item.name + "&nbsp;";
            });
            data.goodsParamShow = `<div style="wdith:220rpx;overflow: hidden;
					 								text-overflow: ellipsis;
					 								white-space: nowrap;
														word-break: break-all;">${data.goodsParamShow}</div>`;
          }
        }

        // 新增商品购买数量
        data.buyNum = 1;

        // 新增商品当前规格价格
        data.curSpePrice = data.defaultPrice;
        // 新增当前规格下的拼单价格
        data.curSpeShareBillPrice = data.defaultPricePinPrice;

        // 如果有 giftGoodsInfo 字段就转化 组合套餐规则
        if (data.buyGiftRule && data.buyGiftRule.giftGoodsInfo) {
          state.giftGoodsInfo = JSON.parse(data.buyGiftRule.giftGoodsInfo);
        }

        let r = RegExp(/<[img]+\s+(.*?)(?<id>\w*?)[\s'"](.*?)>/g);
        let contents = data.paramDetail;
        let matchres = contents.match(r); //返回数组['<el-image ... />','<el-image ... />']

        if (matchres) {
          matchres.forEach((item, index) => {
            let len = item.length;
            let _str =
              item.slice(0, len - 2) +
              ' style="max-width:790px;text-align:center;margin:0 auto;"/>'; //追加之后的img
            contents = contents.replace(item, _str);
          });
        }
        data.paramDetail = contents;
        state.dataObj = data;
        state.loading = false;
      }
    }
    // 参数加码
    // 自定义参数前面
    const addParamSign = (curShareParams) => {
      // return new Promise((resolve, reject) => {
      ApiAddParamSign({
        param: 'goods_id=' + route.query.goodsId + '&ownSale=' + 0 + '&storeId='
      }).then(res => {
        if (res.code == 10000) {
          // console.log(res)
          // resolve(res.data.sign)
          handleGetShareCode('CT_SI=' + res.data.sign)
        }
      })
      // })
    }
    // 分享二维码
    const handleGetShareCode = async (params) => {
      let res = await ApiGetAppletsShareCode({
        page: 'page_goods/pages/detail',
        params
      })
      state.shareUrl = res.data.shareUrl
    }
    watch(() => route.query, async (value, oldVal) => {
      // console.log(value)
      if (value.val == 1) state.isVal = 1
      await handleGetGoodsDetail()
      if (value.goodsId) await addParamSign()
    }, { immediate: true })


    // 选择规格
    function checkspecis (parentIndex, curIndex) {
      let goodsSpecsAttrs = state.dataObj.attrSku[parentIndex].goodsSpecsAttrs;

      // 当前点击的其它同级标签 acrive 置为 false
      goodsSpecsAttrs.map((item, index) => {
        item.active = false;
      });

      goodsSpecsAttrs[curIndex].active = !goodsSpecsAttrs[curIndex].active;

      // 获取当前选中数据
      getCurPitchOnData();
    }

    // 获取当前选中数据
    function getCurPitchOnData () {
      let idArr = [];
      let curPar = [];
      state.dataObj.attrSku.map((item) => {
        item.goodsSpecsAttrs.map((itemTwo) => {
          if (itemTwo.active) {
            curPar.push({
              name: item.attrKey,
              val: itemTwo.attrValue,
            });

            idArr.push(itemTwo.valueId);
          }
        });
      });

      // 当前选中参数
      let curSkuStr = "";
      curPar.map((item) => {
        curSkuStr += "" + item.name + "" + "" + "" + item.val + "";
      });
      state.curSkuString = curSkuStr;

      // 获取到当前选中规格并转化为字符串
      curGoodsSpe(idArr);
    }

    // 获取到当前选中规格并转化为字符串
    function curGoodsSpe (arr) {
      let idStr = null;
      arr.sort((a, b) => {
        return a - b;
      });
      state.cart_gsp = arr.toString();
      idStr = state.cart_gsp.replace(/,/g, "");

      // 根据规格组合刷新最新价格和库存
      setPriceCount(idStr);
    }

    // 根据规格组合刷新最新价格和库存
    function setPriceCount (str) {
      // console.log('组合', str)

      let skuList = state.dataObj.skuList;
      let skuListLen = skuList.length;

      for (let i = 0; i < skuListLen; i++) {
        if (skuList[i].gspId == str) {
          state.dataObj.curSpePrice = state.isOpenPin
            ? skuList[i].pinActivityPrice
            : skuList[i].marketPrice;
          // 拼单价格
          if (state.isOpenPin) {
            state.dataObj.curSpeShareBillPrice = skuList[i].pinActivityPrice;
          } else {
            state.dataObj.curSpePrice = skuList[i].marketPrice;
            state.dataObj.marketPrice = skuList[i].yptMarketPrice;
            state.dataObj.numStore = skuList[i].store;
            console.log(skuList[i].marketPrice)
          }

          state.dataObj.numStore = skuList[i].store;
          state.skuId = skuList[i].skuId;
          break;
        }
      }
    }

    return {
      ...toRefs(state),
      checkspecis
    }
  },

}
</script>
<style scoped lang="less">
.container {
  padding-top: 10px;
  .goods-main {
    display: flex;
    height: 640px;
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .goods-main-left {
    width: 145px;
    height: 620px;
  }
  .goods-main-mid {
    width: 600px;
    height: 620px;
    margin: 0 20px;
  }
  .goods-main-right {
    width: 324px;
    height: 620px;
    .goods-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 68px;
      color: #5f6674;
      height: 87px;
      line-height: 1.7;
    }
    .goods-price-bar {
      margin-bottom: 10px;
      .price {
        font-size: 17px;
        font-weight: bold;
        color: #d03a2b;
        margin-right: 14px;
      }
      .d-price {
        font-size: 14px;
        font-weight: 400;
        color: #dfe0e3;
        text-decoration: line-through;
      }
    }
    .sku {
      // display: flex;
      margin-bottom: 38px;
      .sku-item {
        margin-bottom: 10px;
        .specs-name {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }
    }
    .numStore {
      margin-bottom: 10px;
    }
    .suk-color-active {
      background: #d43834;
      border: 1px solid #d43834;
      color: #fff;
    }
    .suk-color {
      display: inline-block;
      height: 28px;
      border-radius: 4px;
      opacity: 1;
      border: 1px solid #bec3c9;
      text-align: center;
      line-height: 28px;
      padding: 0 10px;
      margin-right: 10px;
      box-sizing: border-box;
    }
  }
  .mini-programer-code {
    width: 120px;
    height: 120px;
    font-size: 12px;
    font-weight: 400;
    color: #5f6674;
    position: relative;
    text-align: center;
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 56px;
      background-color: #dfe0e3;
      position: absolute;
      top: 15px;
    }
    .code-pic {
      width: 110px;
      height: 110px;
      display: block;
      margin: 0 auto 10px;
    }
  }

  .goods-detail {
    .goods-detail-tab {
      display: flex;
      height: 32px;
      border-bottom: 1px solid #3333;
      margin-bottom: 50px;
      .goods-tab-item {
        padding: 8px 19px;
        color: #333;
        border: 1px solid #333;
        border-bottom: 0px;
        margin-right: 48px;
        letter-spacing: 2px;
        &.active {
          background-color: #333;
          color: #fff;
        }
      }
    }
    .goods-detail-content,
    .goods-detail-params {
      padding: 0 34px 30px;
      min-height: 500px;
    }
  }
  .table {
    width: 470px;
  }
  .table-line {
    display: flex;
    margin-bottom: 4px;
    border-bottom: 1px solid #ddd;
    .table-name,
    .table-value {
      width: 50%;
    }
  }
}
</style>