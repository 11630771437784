<template>
  <div class="goods-image">
    <!-- 小图 -->
    <ul class="small scroll">
      <li v-for="(img, i) in images"
          :key="img"
          :class="{ active: i === currIndex }">
        <img @mouseenter="currIndex = i"
             :src="img"
             alt="" />
      </li>
    </ul>
    <div class="large"
         v-show="show"
         :style="[{backgroundImage:`url(${images[currIndex]})`},bgPosition]"></div>
    <div class="middle"
         ref="target">
      <img :src="images[currIndex]"
           alt="">
      <div class="layer"
           v-show="show"
           :style="position"></div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, watch } from "vue";
import { useMouseInElement } from "@vueuse/core";
export default {
  name: "GoodsImage",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  setup (props) {
    const currIndex = ref(0);

    const { position, bgPosition, show, target } = usePreviewImg();
    watch(() => props.images.length, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        currIndex.value = 0
      }
    })
    return { currIndex, position, bgPosition, show, target };
  },
};
const usePreviewImg = () => {
  const target = ref(null);
  const show = ref(false);
  // elementX 鼠标基于容器左上角X轴偏移
  // elementY 鼠标基于容器左上角Y轴偏移
  // isOutside 鼠标是否在模板容器外
  const { elementX, elementY, isOutside } = useMouseInElement(target);
  const position = reactive({ left: 0, top: 0 });
  const bgPosition = reactive({
    backgroundPositionX: 0,
    backgroundPositionY: 0,
  });

  watch([elementX, elementY, isOutside], () => {
    // 控制X轴方向的定位 0-200 之间
    if (elementX.value < 100) position.left = 0;
    else if (elementX.value > 500) position.left = 400;
    else position.left = elementX.value - 100;
    // 控制Y轴方向的定位 0-200 之间
    if (elementY.value < 100) position.top = 0;
    else if (elementY.value > 500) position.top = 400;
    else position.top = elementY.value - 100;
    // 设置大背景的定位
    bgPosition.backgroundPositionX = -position.left * 1.4 + "px";
    bgPosition.backgroundPositionY = -position.top * 1.4 + "px";
    // 设置遮罩容器的定位
    position.left = position.left + "px";
    position.top = position.top + "px";
    // 设置是否显示预览大图
    show.value = !isOutside.value;
  });
  return { position, bgPosition, show, target };
};

</script>
<style scoped lang="less">
.goods-image {
  width: 790px;
  height: 600px;
  position: relative;
  display: flex;
  z-index: 500;
  .large {
    position: absolute;
    top: 0;
    left: 782px;
    width: 600px;
    height: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: 1200px 1200px;
    background-color: #f8f8f8;
  }
  .middle {
    width: 600px;
    height: 600px;
    background: #f5f5f5;
    position: relative;
    cursor: move;
    margin: 0 20px;
    img {
      width: 600px;
      height: 600px;
    }
    .layer {
      width: 200px;
      height: 200px;
      background: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
      position: absolute;
    }
  }
  .small {
    width: 150px;
    height: 620px;
    overflow-y: scroll;
    li {
      width: 145px;
      height: 145px;
      // margin-left: 12px;
      margin-bottom: 15px;
      cursor: pointer;
      img {
        width: 145px;
        height: 145px;
      }
      &:hover,
      &.active {
        border: 2px solid @qdzColor;
      }
    }
  }
}
</style>
